<template lang="pug">
div
  p
    strong
      slot(name='title')
  .form__group
    // TODO:
    // in futuro quando ci sarà tempo
    // sarebe meglio evitare questo trick
    // della label che sovrasta l'input
    // e usare dei normali input con normali eventi js
    label.check(:for='inputKey')
      input.check__input(type="checkbox"
                         @click="[updateCurrentAcceptTerms($event), updateSubscribers($event)]"
                         :id="inputKey"
                         :name="inputKey"
                         v-model="activate"
                         :value="activate")

      span.check__field
      span.check__label.form__label--required
        slot

</template>

<script>
import axios from 'axios'
export default {
  props: {
    inputKey: { required: true, type: String }
  },
  data(){
    return {
      activate: false
    }
  },
  mounted(){
    this.activate = this.$store.state.currentAcceptTerms[this.inputKey]
  },
  methods: {
    updateCurrentAcceptTerms(event) {
      const checked = event.target.checked
      this.$store.commit('changeCurrentAcceptTerms', { key: this.inputKey, value: checked })
    },
    updateSubscribers() {
      const path = `/iniziative/${this.$store.state.initiative.id}/initiative_users_accept_terms`
      const params = this.$store.state.currentAcceptTerms
      axios.patch(path, params)
    }
  }
}
</script>
