<template lang="pug">
div
  accept-term(input-key="authorize_photos")
    template(v-slot:title) Liberatoria Immagini
    span
      | In relazione alla presente iniziativa,
      strong
        |  per me e per tutti gli altri partecipanti adulti sopra indicati
      | , autorizzo il CRAL CA Italia, anche per il tramite di terzi appositamente autorizzati,
      | a realizzare ed eventualmente pubblicare nell'account Instagram o in documenti interni
      | (es. bilancio) immagini fotografiche e riprese audio e/o video.
      strong
        |  Le immagini di minori eventualmente riprese non verranno utilizzate
      span(v-if="photoReleaseUrl") 
        | (
        a(:href="photoReleaseUrl" style="color: black; text-decoration: underline;")
          | apri il documento di riferimento
        | )
  accept-term(input-key="authorize")
    template(v-slot:title) Accettazione penale
    span
      | Prendo atto che in caso di disdetta il CRAL
      | si riserva il diritto di applicare una penale a titolo
      | di rimborso delle quote di partecipazione corrisposte e /o
      | da corrispondere a soggetti terzi (tour operator, agenzie di viaggio, alberghi, ecc.).
  accept-term(input-key="disclaimer1")
    template(v-slot:title) Mandato con rappresentanza al Cral
    span
      | In relazione alla presente iniziativa,
      | conferisco per me e per gli altri aderenti all'iniziativa sopra indicati,
      | avendone i poteri, mandato non oneroso con rappresentanza al CRAL CA Italia.
      | Autorizzo pertanto il CRAL CA Italia a pagare in nome e per conto mio e
      | degli altri aderenti all'iniziativa, i fornitori dei beni e servizi,
      | impegnandomi a fornire la provvista di quanto a tale titolo dovuto.

  accept-term(input-key="disclaimer2")
    template(v-slot:title) Conferma presa visione delle condizioni dell'iniziativa
    span
      | Dichiaro di aver preso visione del programma dell’iniziativa,
      | della quota di partecipazione, dei supplementi (se previsti),
      | delle assicurazioni da stipulare e di tutti gli allegati al programma,
      | nonché di conoscerli e di accettarli nella loro interezza.
      | A mia discrezione potrò eventualmente stipulare - a mia cura e con spese a mio carico -
      | altre polizze assicurative, in aggiunta a quelle proposte,
      | se dovessi ritenere non adeguate le coperture prestate
      | ed i massimali indicati nelle polizze e riassunti dal CRAL CA Italia.


</template>

<script>
import AcceptTerm from './accept-term'
export default {
  components: { AcceptTerm },
  props: {
    photoReleaseUrl: { type: String },
  },
}
</script>
