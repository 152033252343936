
<template lang="pug">
  div
    h3.title.title__block Compila i campi per effettuare l'adesione
    .text__block.user-subscription-alert
      p 
        | ATTENZIONE: I soci ed i familiari dei soci hanno la priorità 
        | sui non soci in fase di adesione a prescindere dalla data 
        | in cui è avvenuta l'adesione.
      p
        | Modulo da compilare tassativamente 
        | entro il <strong>{{expiringDate}}</strong>
      p
        | Nel caso l'anagrafica dei Familiari e Aggregati non fosse 
        | aggiornata puoi modificarla accedendo
        | al tuo  <a :href="profilePath">profilo</a>.

</template>

<script>
export default {
  props: {
    expiringDate: { required: true, type: String },
    profilePath: { required: true, type: String }
  }
}
</script>
