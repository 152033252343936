<template lang='pug'>
  div
    .form-group(v-for="initiativeComponent in initiativeComponents")
      component(v-bind:is="currentComponent(initiativeComponent)"
                :initiative-component="initiativeComponent"
                :initiative-user="subscription"
                style="margin-bottom: 20px")
</template>

<script>
import CheckBoxes from './question_types/check-boxes'
import ComponentText from './question_types/component-text'
import Number from './question_types/number'
import PriceCharge from './question_types/price_charge'
import PriceDeposit from './question_types/price_deposit'
import Price from './question_types/price'
import RadioButtons from './question_types/radio-buttons'

export default {
  components: {
    CheckBoxes,
    ComponentText,
    Number,
    Price,
    PriceCharge,
    PriceDeposit,
    RadioButtons
  },
  props: {
    subscription: { required: true }
  },
  methods: {
    currentComponent(initiativeComponent){
      // text è una parola riservata in js
      return initiativeComponent.component
                                .answer_type
                                .replace('_','-')
                                .replace('text', 'component-text')
    }
  },
  computed: {
    initiativeComponents(){
      return this.$store.state.initiativeComponents
    }
  }
}
</script>
