// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="offices">
//   <select data-target="offices.corporationSelect"></select>
//   <select data-target="offices.officeSelect"></select>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['corporationSelect',
                    'officeSelect',
                    'officesFormGroup']

  connect() {
    this.initializeSelect2()
    this.setupCorporationOnChange()
    this.populateOfficeSelect()
  }

  initializeSelect2(){
    $(this.corporationSelectTarget).select2()
    $(this.officeSelectTarget).select2()
    $(this.corporationSelectTarget).addClass('searchable-select')
    $(this.officeSelectTarget).addClass('searchable-select')
    this.addArrowUI(this.corporationSelectTarget, 1)
    this.addArrowUI(this.officeSelectTarget)
  }

  setupCorporationOnChange(){
    var _this = this

    $(this.corporationSelectTarget).on('select2:select', function (e) {

      // Do something
      let id = e.params.data.id
      var source = '/corporations/' + id + '/offices.json'

      $.getJSON(source, function (result) {

        $(_this.officeSelectTarget).select2('destroy')
        $(_this.officeSelectTarget).val('')
        if (result.length == 0){
          // sostituire con i target
          $(_this.officesFormGroupTarget).hide()
        } else {
          $(_this.officesFormGroupTarget).show()
        }

        $(_this.officeSelectTarget).select2({
          data: result,
          ajax: {
            url: source,
            delay: 200,
            dataType: 'json',
            processResults: function (data) {
              return {
                results: data
              };
            }
          }
        })
        _this.addArrowUI(_this.officeSelectTarget)
      })
    })
  }

  populateOfficeSelect(){
    let _this = this
    let id = $(this.corporationSelectTarget).val()

    if (id == "" || id == undefined) {
      return false
    }

    let source = `/corporations/${id}/offices.json`
    $.getJSON(source).done(function (result) {
      $(_this.officeSelectTarget).select2({ data: result })
      _this.addArrowUI(_this.officeSelectTarget)

      if (result.length == 0){
        // sostituire con i target
        $(_this.officesFormGroupTarget).hide()

      } else {
        $(_this.officesFormGroupTarget).show()
      }

    }).fail(function(jqxhr, textStatus, error){
      console.log(textStatus)
      console.log(error)
    })
  }

  addArrowUI(target, id=0) {
    let $select2 = $(target).next()
    $select2.attr('data-cy', 'AMMO'+id)
    $select2.css({ width: '100%' })

    $select2.find('.select2-selection__arrow  b')
            .detach()

    $select2.find('.select2-selection__arrow')
            .append('<span class="ico ico--freccetta_giu"></span>')
  }
}
