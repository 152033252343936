<template lang="pug">
.form-group
  label(:class="cssClassForLabel")
    | {{initiativeComponent.component.question}}
    | € {{initiativeComponent.value}}
  .form__hint(v-if="initiativeComponent.description" style="margin-top:-5px; margin-bottom:5px;")
    |  {{initiativeComponent.description}}
  .select__wrapper
    .select__icon
      .ico.ico--freccetta_giu
    select.select.user_id(type="text"
                          @change="sendToServer"
                          v-model="answer"
                          :required="this.component.required")
      option(value="0") No
      option(value="1") Si
  .form__hint {{ initiativeComponent.component.hint }}
</template>

<script>
import defaultProps from './default_props'

export default {
  mixins: [defaultProps]
}
</script>
