<template lang="pug">
div
  label(v-text="component.name" :class="cssClassForLabel")
  .check_boxes-component
    div(v-for="el in arrayFromAnswers()")
      input(type="checkbox"
            @change="sendToServer"
            :value="el"
            :id="el"
            v-model="answer"
            style="margin-right: 7px")
      label(v-text="el" :for="el" style="position: relative; top: -2px")

  .form__hint {{ initiativeComponent.description }}
</template>

<script>
import defaultProps from './default_props'

export default {
  data(){
    return {
      // sovrascrivo il valore di default del mixin perchè qui passiamo un array
      answer: []
    }
  },
  mixins: [defaultProps],
  methods: {
    arrayFromAnswers(){
      return this.component.answers.split('\n')
    }
  }
}
</script>
