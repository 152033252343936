<template lang="pug">
div
  label(v-text="component.name" :class="cssClassForLabel")
  input.form__field(type="text"
                    v-model="answer"
                    :required="this.component.required"
                    @change="sendToServer")
  .form__hint {{ initiativeComponent.description }}
</template>

<script>
import defaultProps from './default_props'
export default {
  mixins: [defaultProps],
  data(){
    return {
      answer: ""
    }
  }
}
</script>
