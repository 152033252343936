<template lang="pug">
.u-text--center.user-subscription-confirm
  button.btn.btn--purple(type='submit'
                         name='confirm'
                         @click="confirmClicked"
                         style='margin-left: 15px')
    span.btn__text.confirmable(data-msg-confirm="Una volta confermata l'adesione non potrai più modificarla. Vuoi proseguire?")
      | Conferma
</template>

<script>
export default {
  methods: {
    confirmClicked(e) {
      var stop = false;
      var msg = "E' necessario accettare le clausole richieste";
      if( !$('#authorize').is(':checked') ) {
        stop = true;
        $('#authorize').parent().addClass( 'generic-error' );
      }
      if( !$('#authorize_photos').is(':checked') ) {
        stop = true;
        $('#authorize_photos').parent().addClass( 'generic-error' );
      }
      if( !$('#disclaimer1').is(':checked') ) {
        stop = true;
        $('#disclaimer1').parent().addClass( 'generic-error' );
      }
      if( !$('#disclaimer2').is(':checked') ) {
        stop = true;
        $('#disclaimer2').parent().addClass( 'generic-error' );
      }
      if( stop ) {
        event.preventDefault();
        alert( msg );
      }
    }
  }
}
</script>
