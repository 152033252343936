<template lang="pug">
.form-group.subscribe(v-if="loaded")
  label.form__label.form__label--required
    | Partecipante
  .select__wrapper.fake-input(@click="userClicked")
    .select__icon
      .ico.ico--freccetta_giu
    span.fullname {{this.currentUserFullname}}

  ul.users-available(v-if="showPickerUser")
    li(v-for="user in availableUsers"
       v-text="user.fullname"
       v-bind:value="user.id"
       @click.prevent="newUserIsClicked")
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default{
  computed: {
    ...mapGetters(['findUserById']),
    ...mapState(['availableUsers',
                 'allUsers',
                 'loaded',
                 'debug']),

     currentUserFullname(){
       return this.findUserById(this.subscription.user_id).fullname
     },
  },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      selectedId: null,
      currentUser: null,
      showPickerUser: false
    }
  },
  methods: {
    userClicked(){
      if (this.showPickerUser){
        this.showPickerUser = false
      } else {
        this.showPickerUser = true
      }
    },
    newUserIsClicked(evt){
      const userId = evt.target.getAttribute('value')
      if (this.subscription.user_id == 0) {
        this.$store.dispatch('addSubscription', userId)
      } else {
        this.$store.dispatch('userIsChanged', {
          userId: userId,
          initiativeStandardUserId: this.subscription.id
        })
      }
      this.showPickerUser = false
    }
  }
}
</script>

<style scoped>
.subscribe {
  margin-bottom: 20px;
}

.subscribe > .fake-input {
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 0px
}

.subscribe > .users-available {
  margin: 0;
  padding: 0;
  padding-top: 6px;
  margin-bottom: 20px
}

.subscribe > .users-available > li {
  margin: 10px 0;
  padding: 0;
  padding-left: 20px;
  list-style-type: none;
  cursor: pointer;
}
.subscribe > .users-available {
  background-color: #ddd;
  padding-bottom: 10px;
}
.subscribe > .fullname {
  padding-left: 10px;
  height: 44px;
}
</style>
