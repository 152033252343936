import axios from 'axios'

export default {
  loadData(state, initiative){
    this.commit('setInitiative', initiative)

    const path = `/iniziative/${initiative.id}/initiative_users`
    axios.get(path)
          .then(response => {
            const data = response.data
            this.commit('loadData', data)
            this.commit('debugStatus')
            if (data.initiative_users.length == 0) {
              this.commit('initializeSubscription')
            }
          })
          .catch(error => {
            console.log(error)
            console.log(error.response)
          })
  },
  initializeSubscription({commit, state}){
    commit('initializeSubscription')
  },
  addSubscription({commit, state}, userId){
    const path = `/iniziative/${state.initiative.id}/initiative_users`
    let iban = state.currentIban
    let acceptTerms = state.currentAcceptTerms
    axios.post(path, { user_id: userId,
                       iban: iban.code,
                       account_holder: iban.holder,
                       authorize: acceptTerms.authorize,
                       diclaimer1: acceptTerms.diclaimer1,
                       diclaimer2: acceptTerms.diclaimer2 })
         .then((response) => {
           this.commit('addSubscription', response.data)
           this.commit('removeSubscriptionInitialization', response.data)
         })
         .catch((error) => {
           console.log(error.response)
           this.commit("setErrors", error.response.data.errors)
         })
  },
  setInitiative({}, initiative){
    this.commit('setInitiative', initiative)
  },
  removeSubscription({}, id){
    const path = `/initiative_users/${id}`
    const _this = this
    axios.delete(path)
         .then(function (response) {
           _this.commit('deleteSubscription', response.data)
         })
         .catch(function (error, data) {
           _this.commit("setErrors", error.response.data.errors)
         })
  },
  removeSubscriptionInitialization({}) {
    this.commit('removeSubscriptionInitialization')
  },
  userIsChanged({}, data){
    const path = `/initiative_users/${data.initiativeStandardUserId}`
    const params = { user_id: data.userId }
    axios.patch(path, params)
         .then(response => {
           this.commit('updateUser', response.data)
           this.commit("clearErrors")
         })
         .catch(error => {
           this.commit("setErrors", error.response.data.errors)
         })
  },
  updateSubscription({}, data){
    const path = `/initiative_users/${data.initiativeUserId}`
    const params = {
      initiative_component_id: data.initiativeComponentId,
      answer_value: data.answerValue
    }

    if (data.answerValue != null) {
      axios.patch(path, params)
           .then((response) => {
             this.commit('updateSubscription', response.data)
           })
           .catch(error => {
             this.commit("setErrors", error.response.data.errors)
           })
    } else {
      console.log("Answer value is empty", data.answerValue, data)
    }
  }
}
