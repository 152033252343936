<template lang="pug">
  div
    pre(v-if="debug" v-text="amountDebug")
    user(:subscription="subscription")
    questions(v-if="!isNewSubscription" :subscription="subscription")
    remover(v-if="!firstSubscription && !isNewSubscription" :id="subscription.id")
</template>

<script>
import User from './inputs/user'
import Remover from './inputs/remover'
import Questions from './inputs/questions'
import { mapState } from 'vuex'
export default{
  computed: {
    ...mapState(['debug']),
    amountDebug(){
      return `Amount: ${this.subscription.amount} / Calc amount: ${this.subscription.calc_amount}`
    }
  },
  components: {
    Questions,
    Remover,
    User
  },
  props: {
    subscription: { required: true },
    firstSubscription: { default: false }
  },
  computed: {
    isNewSubscription () {
      return this.subscription.user_id == 0
    }
  },
  methods: {
    userIsChanged(e){
      console.log("CAMBIO!", e)
    }
  }
}
</script>
