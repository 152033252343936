<template lang="pug">
.l-row
  .l-col-sm--6
    .form__group
      label.form__label.form__label--required(for="iban") IBAN di addebito
      .select__wrapper
        .select__icon
          .ico.ico--freccetta_giu
        select.select.form__field--required(v-model="selected" @change="[updateCurrentIban($event), updateSubscribers($event)]")
          option(v-for="(iban, index) in ibans"
                 v-text="iban.code"
                 :value="iban")
  .l-col-sm--6
    .form__group
      label.form__label(for="account_holder")
        | Intestato/cointestato a:
      input.form__field.account_holder(disabled="true"
                                       name="account_holder"
                                       :value="selected.holder")
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['ibans'])
  },
  data(){
    return {
      selected: {}
    }
  },
  mounted(){
    this.selected = this.$store.state.currentIban
  },
  methods: {
    updateCurrentIban() {
      this.$store.commit('setCurrentIban', this.selected)
    },
    updateSubscribers() {
      const path = `/iniziative/${this.$store.state.initiative.id}/initiative_users_ibans`
      const params = {
        account_holder: this.selected.holder,
        iban: this.selected.code
      }
      axios.patch(path, params)
    }
  }
}
</script>
