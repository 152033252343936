import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['iban', 'holder', 'holderValue']

  connect() {
    this.changeAccountHolder()
  }

  changeAccountHolder() {
    var name = $(this.ibanTarget).find(':selected').data('name')
    $(this.holderTarget).val(name)
    $(this.holderValueTarget).val(name)
  }

}