// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="offices">
//   <select data-target="offices.corporationSelect"></select>
//   <select data-target="offices.officeSelect"></select>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['url']

  connect() {
    this.load()
    this.startRefreshing()
  }

  load() {
    const url = this.data.get("url")
    if(url != undefined) {
      fetch(url)
        .then(response => response.text())
        .then(html => {
          this.element.innerHTML = html
        })
    }
  }

  openModalNew(e){
    e.preventDefault()
    const type = e.currentTarget.dataset.type
    const url =  `${this.currentHost()}/aggregates/new?aggregate[type]=${type}`
    $('.remodal iframe').attr( 'src', url);
    window.remodal.open();
  }

  openModalEdit(e){
    e.preventDefault()
    const member = e.currentTarget.dataset.member
    const url = `${this.currentHost()}/aggregates/${member}/edit`
    $('.remodal iframe').attr('src', url);
    window.remodal.open()
  }

  currentHost(){
    const port = location.port ? ':'+location.port: ''
    const prefix = `${location.protocol}//${location.hostname}`
    return `${prefix}${port}`
  }

  startRefreshing() {
    this.refreshTimer =  setInterval(() => {
      this.load()
    }, 3000)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}
