export default {
  props: {
    initiativeUser: { required: true, type: Object },
    initiativeComponent: { required: true, type: Object }
  },
  data(){
    return {
      answer: null
    }
  },
  computed: {
    component(){
      return this.initiativeComponent.component
    },
    cssClassForLabel(){
      return {
        'form__label': true,
        'form__label--required': this.component.required
      }
    }
  },
  methods: {
    sendToServer(e){
      this.$store.dispatch('updateSubscription', {
        initiativeUserId: this.initiativeUser.id,
        initiativeComponentId: this.initiativeComponent.id,
        answerValue: this.answer
      })
    }
  },
  mounted(){
    let tempAnswers = this.initiativeUser.answers
    if (tempAnswers == null) { return null }

    let keys = Object.keys(tempAnswers)
    let key = keys.filter((key) => {
      const currentKey = key.split('--')[0]
      if(currentKey == this.component.slug) {
        return true
      }
    })
    this.answer = tempAnswers[key[0]]
  }
}
