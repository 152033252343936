<template lang="pug">
div(v-if="arrayFromAnswers.length")
  label(:class="cssClassForLabel" v-text="initiativeComponent.component.name")
  div(v-for="el in arrayFromAnswers")
    input(type='radio'
          :name="`${initiativeUser.user_id}-${initiativeComponent.id}-${component.slug}`"
          @change='[setAnswer($event), sendToServer($event)]'
          :id="`${initiativeUser.user_id}-${initiativeComponent.id}-${el}`"
          :value="el"
          :checked="el == answer || undefined"
          style="margin-right: 5px"
          :required="component.required")
    label(:for="el" v-text="el" style="position: relative; top: -2px")
  .form__hint {{ initiativeComponent.description }}
</template>

<script>
import defaultProps from './default_props'

export default {
  mixins: [defaultProps],
  computed: {
    arrayFromAnswers(){
      const value = this.initiativeComponent.value
      if (value == null || value == "") {
        return []
      } else {
        return value.split(',')
      }
    },
  },
  methods: {
    // NB: evitato v-model perchè, cambiando la valutazione dell'attributo "checked"
    // (veniva sempre impostato, anche prima del primo inserimento)
    // provocava la colorazione rossa dei radio anche prima della prima selezione quando obbligatori
    setAnswer(event){
      this.answer = event.target.value
    }
  }
}
</script>
