import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['url']

  connect() {
    const totalCost = parseInt(this.data.get("cost"))
    this.writeTotal(totalCost)
  }

  calculate(evt){
    var target         = evt.target
    var _this          = this
    var extra          = $(target).data('extra')
    var component_id   = $(target).data('component-id')
    var component_slug = $(target).data('component-slug')

    $.ajax({
      type: 'get',
      url: '/answer',
      data: {
        component_id: component_id,
        component_slug: component_slug,
        value: extra
      },
      success(response) {
        var value = $(target).val()
        var totalCost = parseInt(_this.data.get("cost"))
        if(value==0){
          totalCost -= response.cost
        } else {
          totalCost += response.cost
        }
        _this.writeTotal(totalCost)
      },
      error(xhr) {
        console.log('Errore chiamata AJAX', xhr)
      }
    })
  }

  removeBlock(evt){
    var btn = evt.target
    var block = $(btn).closest('.block__wrapper');
    var nodeWithdataExtra = block.find('[data-extra]')
    this.subtract(nodeWithdataExtra)
    block.hide();
    block.removeClass('user-subscription');
  }

  subtract(nodeWithdataExtra){
    var _this = this

    nodeWithdataExtra.each(function(index){
      var value = $(this).val()
      var totalCost = parseInt(_this.data.get("cost"))
      // value == 1 significa che l'utente ha selezionato si
      if (value == 1) {
        var extra = $(this).data('extra')
        totalCost -= extra
        _this.writeTotal(totalCost)
      }
      // console.log("ASD", extra)
    })
  }

  writeTotal(tot){
    if( tot < 0 ) {
      tot = 0;
    }
    this.data.set("cost", tot)
    $('#tot').html( '€ ' + tot.toFixed(2).toString().replace( '.', ',' ) );
  }
}
