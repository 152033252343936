import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    debug: false,
    loaded: false,
    errors: null,
    free: null,
    initiative: {},
    ibans: null,
    currentIban: null,
    currentAcceptTerms: null,
    initiativeComponents: [],
    initiativeStandardUsers: [],
    allUsers: [],
    availableUsers: [],
    newInitiativeStandardUser: false,
    total: 0
  },
  mutations,
  getters: {
    confirmable: (state) => {
      return state.initiativeStandardUsers.length > 0
    },
    findUserById: (state) => (user_id) => {
      let user = state.allUsers.find(user => user.id == user_id)
      if (user == undefined) { user = { fullname: 'Scegli', id: 0, text: "" } }
      return user
    }
  },
  actions
})

export default store
