import 'select2'
import 'whatwg-fetch'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@stimulus/polyfills'
import 'controllers'

import setupSubscribe from '../components/subscribe/setup_subscribe'

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('js-subscribe')
  if (el != null) {
    setupSubscribe(el)
  }
})
