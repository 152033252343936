<template lang="pug">
a.btn.btn--grey.user-subscription__btn(@click.prevent="clicked" href="#" style="margin-bottom: 70px")
  span.btn__text Rimuovi partecipante
</template>
<script>
export default {
  props: {
    id: { required: true }
  },
  methods: {
    clicked(){
      if (this.id) {
        this.$store.dispatch('removeSubscription', this.id)
      } else {
        this.$store.dispatch('removeSubscriptionInitialization')
      }
    }
  }
}
</script>
