function devOrStaging(){
  const parsedUrl = new URL(window.location.href)
  return parsedUrl.hostname == 'localhost' || parsedUrl.hostname == 'ca-cral.agricole.sdchlab.develon.com'
}

export default {
  loadData(state, data){
    state.initiativeStandardUsers = data.initiative_users
    state.total = data.total
    state.availableUsers = data.available_users
    state.allUsers = data.all_users
    // Specifico solo per la mutazione load:

    state.initiativeComponents = data.initiative_components
    state.loaded = true
    state.free = data.free
    state.ibans = data.ibans
    if (data.current_iban != null) {
      state.currentIban = data.current_iban
    }
    if (data.current_accept_terms != null) {
      state.currentAcceptTerms = data.current_accept_terms
    }
  },
  // TODO probabilmente possiamo unire in un unico metodo
  initializeSubscription(state, data){
    state.newInitiativeStandardUser = true
  },
  addSubscription(state, data){
    state.initiativeStandardUsers = data.initiative_users
    state.total = data.total
    state.availableUsers = data.available_users
    state.allUsers = data.all_users
  },
  updateSubscription(state, data){
    state.initiativeStandardUsers = data.initiative_users
    state.total = data.total
    state.availableUsers = data.available_users
    state.allUsers = data.all_users
  },
  updateUser(state, data){
    state.initiativeStandardUsers = data.initiative_users
    state.total = data.total
    state.availableUsers = data.available_users
    state.allUsers = data.all_users
    state.initiativeStandardUsers.find(user => user.id == data.initiative_user.id)
                                 .user_id = data.initiative_user.user_id
  },
  deleteSubscription(state, data){
    state.initiativeStandardUsers = data.initiative_users
    state.total = data.total
    state.availableUsers = data.available_users
    state.allUsers = data.all_users
    if (data.initiative_users.length == 0) {
      state.newInitiativeStandardUser = true
    }
  },
  removeSubscriptionInitialization(state) {
    state.newInitiativeStandardUser = false
  },
  setCurrentIban(state, iban){
    state.currentIban = iban
  },
  changeCurrentAcceptTerms(state, data){
    state.currentAcceptTerms[data.key] = data.value
  },
  clearErrors(state) {
    state.errors = []
  },
  setErrors(state, errors) {
    if (errors == undefined){
      const msg = "Errore generico. Se l'errore persiste contattare l'assistenza."
      console.error(msg)
      state.errors = [msg]
    } else {
      state.errors = errors
    }
  },
  setAllUsers(state, users) {
    state.allUsers = users
  },
  setAvailableUsers(state, users){
    state.availableUsers = users
  },
  setInitiative(state, initiative) {
    state.initiative = initiative
  },
  setInitiativeStandardUsers(state, initiativeStandardUsers){
    state.initiativeStandardUsers = initiativeStandardUsers
  },
  setTotal(state, total) {
    state.total = total
  },
  setInitiativeFree(state, free){
    state.free = free
  },
  isLoaded(state) {
    state.loaded = true
  },
  debugStatus(state) {
    if (devOrStaging()){
      const parsedUrl = new URL(window.location.href)
      const debugValue = parsedUrl.searchParams.get("debug")
      state.debug = debugValue == "true"
    }
  }
}
