<template lang="pug">
.number-component
  label(:class="cssClassForLabel" v-text="component.name")
  input.form__field(type="number"
                    :required="this.component.required"
                    v-model="answer"
                    @input="sendToServer")
</template>

<script>
import defaultProps from './default_props'

export default {
  mixins: [defaultProps]
}
</script>
