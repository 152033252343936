<template lang="pug">
.block__wrapper
  a.btn.btn--green(href="#" @click.prevent="addSubscription")
    span.btn__text Aggiungi partecipante
</template>

<script>
export default {
  methods: {
    addSubscription(){
      this.$store.dispatch('initializeSubscription')
    }
  }
}
</script>
