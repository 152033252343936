import store from './store/index'
import VueCsrf from 'vue-csrf';
import Vue from 'vue/dist/vue.esm'

Vue.use(VueCsrf)

import FormForInitiative from './form-for-initiative'
import SubscribeHeader from './subscribe-header'

import axios from 'axios'

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': Vue.csrfToken
};


export default function(el) {
  new Vue({
    store,
    el: el,
    components: {
      FormForInitiative,
      SubscribeHeader
    },
    data(){
      return {
        initiative: null
      }
    },
    beforeMount(){
      let initiative = this.$el.attributes.getNamedItem("initiative").value
      this.initiative = JSON.parse(initiative)
    },
    mounted(){
      this.$store.dispatch('loadData', this.initiative)
    }
  })
}
