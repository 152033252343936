import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['description', 'selection', 'toggleable']

  connect() {
    this.cacheDescription()
    this.toggle()
  }

  cacheDescription() {
    this.cachedDescription = this.descriptionTarget.value
  }

  toggle() {
    if (this.selectionTarget.value == 'other') {
      this.descriptionTarget.value = this.cachedDescription
      this.toggleableTarget.style.display = "block"
    } else {
      this.descriptionTarget.value = ""
      this.toggleableTarget.style.display = "none"
    }
  }
}