<template lang="pug">
  form.edit_subscription_form(:action="url" method="post")
    input(type='hidden' name="authenticity_token" :value="$csrf.get()")
    .l-row.block__wrapper.user-subscription
      .l-col-md--8
        subscriptions
    div(v-if="this.confirmable")
      iban
      accept-terms(:photo-release-url="photoReleaseUrl")
      btn-confirm
</template>
<script>
import AddSubscription from './add-subscription'
import AcceptTerms from './accept-terms'
import BtnConfirm from './btn-confirm'
import Iban from './iban'
import Subscriptions from './subscriptions'
export default {
   components: {
    AcceptTerms,
    AddSubscription,
    BtnConfirm,
    Subscriptions,
    Iban
  },
  props: {
    url: { required: true, type: String },
    photoReleaseUrl: { type: String },
  },
  computed: {
    confirmable(){
      return this.$store.getters.confirmable
    }
  },
}
</script>