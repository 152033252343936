<template lang="pug">
div
  h3.text-danger(v-for="error in this.$store.state.errors" v-text="error" style="color: red")
  div.mb-2(v-if="debug")
    pre Utenti a disposizione per l'iscrizione
    ul
      li(v-for="user in availableUsers" v-text="user.fullname")
    pre Utenti collegati (in generale) a questo utente
    ul
      li(v-for="user in allUsers" v-text="user.fullname")
  subscription(v-for="subscription in this.initiativeUsers"
              :subscription="subscription"
              v-bind:key="subscription.id")
  subscription(v-if="showNew"
              :firstSubscription="this.userSubscribed == 0"
              :subscription="{ user_id: 0, calc_amount: 0 }"
              v-bind:key="0")
  add-subscription(v-if="showAddSubscription")
  br
  div(v-if="userSubscribed > 0")
    .title.title__block(v-if="free == false")
      | Prezzo Totale: € {{total}}
</template>

<script>
import Subscription from './subscription'
import AddSubscription from './add-subscription'
import { mapState } from 'vuex'
export default {
  components: {
    Subscription,
    AddSubscription
  },
  computed: {
    ...mapState(['availableUsers', 'allUsers', 'loaded', 'debug']),
    enoughUsers(){
      return this.userSubscribed < this.usersAvailableLength
    },
    usersAvailableLength(){
      return this.availableUsers.length
    },
    userSubscribed(){
      return this.$store.state.initiativeStandardUsers.length
    },
    free(){
      return this.$store.state.free
    },
    initiativeUsers(){
      return this.$store.state.initiativeStandardUsers
    },
    initiative(){
      return this.$store.state.initiative
    },
    showAddSubscription() {
      return !this.showNew && this.enoughUsers
    },
    showNew() {
      return this.$store.state.newInitiativeStandardUser
    },
    total(){
      const totalTemp = this.$store.state.total
      if (totalTemp <= 0) {
        return 0
      } else {
        return totalTemp
      }
    }
  }
}
</script>
