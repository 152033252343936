import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['shippings', 'section', 'office', 'address', 'email', 'other']

  connect() {
    this.toggleShippingFields()
  }

  toggleShippingFields() {
    var _this = this
    switch (this.shippingsTarget.value) {
      case 'sezione':
        $(_this.sectionTarget).show()
        $(_this.officeTarget).hide()
        $(_this.addressTarget).hide()
        $(_this.emailTarget).hide()
        $(_this.otherTarget).hide()
        break;
      case 'ufficio':
        $(_this.sectionTarget).hide()
        $(_this.officeTarget).show()
        $(_this.addressTarget).hide()
        $(_this.emailTarget).hide()
        $(_this.otherTarget).hide()
        break;
      case 'indirizzo':
        $(_this.sectionTarget).hide()
        $(_this.officeTarget).hide()
        $(_this.addressTarget).show()
        $(_this.emailTarget).hide()
        $(_this.otherTarget).hide()
        break;
      case 'email':
        $(_this.sectionTarget).hide()
        $(_this.officeTarget).hide()
        $(_this.addressTarget).hide()
        $(_this.emailTarget).show()
        $(_this.otherTarget).hide()
        break;
      case 'altro':
        $(_this.sectionTarget).hide()
        $(_this.officeTarget).hide()
        $(_this.addressTarget).hide()
        $(_this.emailTarget).hide()
        $(_this.otherTarget).show()
        break;
      default:
        $(_this.sectionTarget).hide()
        $(_this.officeTarget).hide()
        $(_this.addressTarget).hide()
        $(_this.emailTarget).hide()
        $(_this.otherTarget).hide()
    }
  }
}